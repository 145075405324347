import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState, useMemo } from "@wordpress/element";
import { Pagination, EffectFade } from "swiper";
import { __ } from "@wordpress/i18n";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import classnames from "classnames";
import "react-rangeslider/lib/index.css";
import Slider from "react-rangeslider";

export default function App({ button, content, submitForm }) {
  const container = useRef(null);
  const swiper = useRef(null);
  const [isLastPage, setLastPage] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(!button);
  const range_items = prepareSelectOption(content.screen_one.employees);
  const [sending, setSending] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [selectedTags, setSelectedTags] = useState({});
  const [packageType, setPackageType] = useState(0);

  useEffect(() => {
    if (isPopupActive) {
      //dataLayer.push({ event: "plannerPopupOpen" });
      document.body.classList.add("popup-is-open");
    } else {
      document.body.classList.remove("popup-is-open");
    }
  }, [isPopupActive]);

  const dataLayer = useMemo(() => {
    if (window["dataLayer"]?.push) {
      return window["dataLayer"];
    }
    return () => ({ push: () => ({}) });
  }, []);

  const tagsV2 = content?.screen_two?.plan_tags_v2 || [];
  const tagsHighPriority = content.screen_two?.plan_tags_hp || [];
  const allowedPkgs = content?.screen_two?.allowed_packages || [];
  const packagesContent = content.screen_four_packages;

  const flattenSelectedTags = useMemo(() => Object.values(selectedTags).flat() || [], [selectedTags]);

  function toggleTagsSelection(tag, pkg) {
    let _selected = JSON.parse(JSON.stringify(selectedTags));

    // set if undefined
    if (!_selected[pkg]) {
      _selected[pkg] = [];
    }

    const index = _selected[pkg].findIndex((_tag) => _tag === tag);

    // Add tag
    /*if (index === -1) {
      _selected[pkg].push(tag);

      dataLayer.push({
        event: "plannerTagSelect",
        value: tag,
        selectedTags: [...flattenSelectedTags, tag].join(", "),
      });
    } else {
      _selected[pkg].splice(index, 1);

      dataLayer.push({
        event: "plannerTagDeselect",
        value: tag,
        selectedTags: [...flattenSelectedTags, tag].join(", "),
      });
    }*/

    // update state
    setSelectedTags(_selected);
  }

  const packagesObj = useMemo(() => {
    function prepare(tags) {
      const _pkgs = {};
      const _originalOrder = [];

      // create empty pkgs
      // allowedPkgs.forEach((key) => {
      //   _pkgs[key] = [];
      // });

      [...tags].forEach(({ package: key, tag_name: name }) => {
        if (allowedPkgs.includes(key)) {
          if (typeof _pkgs[key] === "undefined") {
            _pkgs[key] = [];
          }
          _pkgs[key].push(name);
          _originalOrder.push({ key, name });
        }
      });

      return {
        _pkgs,
        _originalOrder,
      };
    }

    const { _pkgs: pkgs, _originalOrder: pkgsOriginalOrder } = prepare(tagsV2);
    //const { _pkgs: hpPkgs } = prepare(tagsHighPriority);

    return {
      pkgs,
      //hpPkgs,
      pkgsOriginalOrder,
      // hpPkgsOriginalOrder
    };
  }, []);

  const [values, setValues] = useState({
    name: "",
    phone: "",
    business: "",
    employee: 1,
    payment_type: "",
    account_option: "",
    email: "",
  });

  const DEFAULT_ERRORS = {
    name: false,
    business: false,
    employee: false,
    email: false,
    phone: false,
    tags: false,
  };

  const [errors, setErrors] = useState(DEFAULT_ERRORS);

  function resetState() {
    setSelectedTags({});

    setValues({
      name: "",
      business: "",
      employee: 1,
      payment_type: "",
      account_option: "",
      email: "",
    });
  }

  function onChange(e) {
    const _values = { ...values };
    _values[e.target.name] = e.target.value;

    // dataLayer.push({
    //   event: 'onChange',
    //   fromName: e.target.name,
    //   value: e.target.value
    // })

    setValues(_values);
  }

  const settings = {
    modules: [Pagination, EffectFade],
    pagination: {
      clickable: false,
    },
    draggable: false,
    allowTouchMove: false,
    autoHeight: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    onSwiper(_swiper) {
      swiper.current = _swiper;
    },
    onSlideChange(_swiper) {
      setLastPage(_swiper.isEnd);
    },
  };

  function activePopup() {
    trackData("designLoesningOpen");
    setIsPopupActive(true);
  }

  async function trackData(event = null, data = {}) {
    if (!event) return;

    const employeeMapping = {
      1: "1-14",
      2: "15-49",
      3: "50-99",
      4: "100-174",
      5: "175-299",
      6: "300+"
    };

    console.log(data);

    if (data.employee && employeeMapping[data.employee]) {
        data.employee = employeeMapping[data.employee];
    }

    let data_object = {
      event: event,
      ...(Object.keys(data).length > 0 ? data : {}),
    };
    // Push event to the data layer (Google Tag Manager or similar)
    dataLayer.push(data_object);

    console.log("datalayer --> ", event, data_object);

    // Push event to the data layer (Google Tag Manager or similar)
    /*dataLayer.push({
      event: event,
      ...data,
    });*/

    //console.log("datalayer --> ", event, data);

    // Prepare formData for the server-side tracking
    const formData = new FormData();
    formData.append("action", "intempus_analytics");
    formData.append("event", event); // Dynamically send event data

    await fetch(content?.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    });
  }

  if (typeof window.__planner_active !== "function") {
    window.__planner_active = activePopup;
  }

  useEffect(() => {
    // popup activate
    button?.addEventListener("click", activePopup);
    return () => {
      document.body.classList.remove("popup-is-open");
      button?.removeEventListener("click", activePopup);
    };
  }, []);

  async function onSubmit(e, device) {
    e.preventDefault();

    if (sending) return;

    const _errors = JSON.parse(JSON.stringify(DEFAULT_ERRORS));

    if (device === "phone") {
      if (!values.phone) {
        _errors.phone = true;
      }
    }

    if (device === "email") {
      if (!values.email) {
        _errors.email = true;
      }
    }

    if (!values.email && !values.phone) {
      setErrors(_errors);
      return;
    }

    setErrors(_errors);
    setSending(true);

    await submitForm({
      ...values,
      tags: flattenSelectedTags.join(", "),
      employee: range_items[values["employee"] - 1 || 0]?.label,
      package: packagesContent?.[packageType]?.title,
    }).then(() => {
      trackData("designLoesning", values);

      /*dataLayer.push({
        event: "designLoesning",
        email: values["email"],
        selected_tags: flattenSelectedTags.join(", "),
        solution: packagesContent?.[packageType]?.title,
        lonsystem: values["payment_type"],
        employee: values["employee"],
      });
      */
      setConfirmSubmit(true);
    });
    setSending(false);
  }

  function next(e) {
    e?.preventDefault();

    if (swiper.current) {
      swiper.current.slideNext();
    }
  }

  function prev(e) {
    e?.preventDefault();
    setErrors(DEFAULT_ERRORS);
    if (swiper.current) {
      swiper.current.slidePrev();
    }
  }

  function prepareSelectOption(options) {
    if (!options) return [];
    let _opt = options.split(/\r\n/);
    if (!_opt.length) return [];

    _opt = _opt.flatMap((_option) => {
      const opt = _option.split(":");

      if (!Array.isArray(opt) || opt.length !== 2) {
        return [];
      }

      return {
        key: opt[0].trim(),
        label: opt[1].trim(),
      };
    });

    return _opt;
  }

  function closePopup() {
    setIsPopupActive(false);
    swiper.current?.slideTo(0);

    trackData("designLoesningClose");

    /*dataLayer.push({
      event: "signupPopupClose",
    });*/

    resetState();
  }

  function submitScreenOne() {
    const _errors = JSON.parse(JSON.stringify(DEFAULT_ERRORS));
    let hasError = false;

    /*
	Needs to be moved
	if (!values.name) {
      _errors.name = true;
      hasError = true;
    }*/

    if (!values.business) {
      _errors.business = true;
      hasError = true;
    }

    if (hasError) {
      setErrors(_errors);
      return;
    }

    trackData("designLoesningStep_1", values);

    /*dataLayer.push({
      event: "plannerPopupStepOneDone",
      value: "Done",
    });
    */

    next();
  }

  //const checker = (arr, target) => target.every((v) => arr.includes(v));
  const checker = (arr, target) => target.every((v) => arr.includes(v));

  function updatePackageType() {
    //console.log("Updating package type with selected tags and allowed packages:", selectedTags, allowedPkgs);

    // Filter allowed packages to get only those defined in packagesObj["pkgs"]
    const hpAllowedPkgs = allowedPkgs.filter((pkg) => typeof packagesObj["pkgs"][pkg] !== "undefined");
    //console.log("High priority allowed packages:", hpAllowedPkgs);

    let highestPackageType = -1;

    // Iterate over allowed packages to find the highest priority package
    for (let j = 0; j < allowedPkgs.length; j++) {
      let _key = allowedPkgs[j];
      //console.log("Checking package:", _key);

      if (!hpAllowedPkgs.includes(_key)) {
        continue;
      }

      const packageTags = packagesObj["pkgs"][_key];
      //console.log("Package tags for", _key, ":", packageTags);

      // Check if any of the package tags match the selected tags
      const hasMatch = packageTags.some((tag) => flattenSelectedTags.includes(tag));
      //console.log(`Matching ${_key} with selected tags ${flattenSelectedTags}: ${hasMatch}`);

      if (hasMatch) {
        //console.log("Package type matched:", _key, "at index", j);
        highestPackageType = j; // Always update to the last matching package
      }
    }

    // Set the highest package type found
    if (highestPackageType !== -1) {
      setPackageType(highestPackageType);
      //console.log("Set highest package type to:", highestPackageType);
    } else {
      //console.log("No matching package type found.");
    }
  }

  function submitScreenTwo() {
    if (flattenSelectedTags.length) {
      updatePackageType();
      next();

      trackData("designLoesningStep_2", { flattenSelectedTags: flattenSelectedTags });

      /*dataLayer.push({
        event: "plannerTagStepDone",
        value: "secondStep",
        tags: flattenSelectedTags.join(", "),
      });*/

      return;
    }

    const _errors = JSON.parse(JSON.stringify(DEFAULT_ERRORS));
    _errors.tags = true;
    setErrors(_errors);
  }

  function submitScreenThree() {
    // Update the package type based on selected tags
    updatePackageType();

    // Log the current package type for debugging
    console.log("Current packageType:", packageType);

    // Update package type based on payment_type
    if (values["payment_type"] !== "ingen" && values["payment_type"] !== "" && values["payment_type"] !== "none" && packageType === 0) {
      setPackageType(1);
      console.log("Updated package to 1 based on payment_type:", values["payment_type"]);
    }

    // Update package type based on account_option
    if (values["account_option"] !== "ingen" && values["account_option"] !== "" && values["account_option"] !== "none" && (packageType === 0 || packageType === 1)) {
      setPackageType(2);
      console.log("Updated package to 2 based on account_option:", values["account_option"]);
    }

    // Push data to dataLayer for tracking
    /*dataLayer.push({
      event: "stepDone",
      value: "thirdStep",
    });*/

    console.log("Step 3 values -> ");
    console.log(values);

    trackData("designLoesningStep_3", values);

    //trackData("designLoesningStep_4");

    /*dataLayer.push({
      event: "plannerPackageSelect",
      value: packagesContent?.[packageType]?.title,
    });*/

    // Move to the next step
    next();
  }

  function submitScreenFour() {
    next();

    /*if (!values.name) {
      _errors.name = true;
      hasError = true;
    }*/

    /*
    dataLayer.push({
      event: "stepDone",
      value: "thirdStep",
    });

    dataLayer.push({
      event: "plannerPackageSelect",
      value: packagesContent?.[packageType]?.title,
    });
    */

    console.log("Step 4 values -> ");
    console.log(values);

    trackData("designLoesningStep_4", values);
  }

  const rangeLabels = {};

  range_items.forEach((item, index) => {
    rangeLabels[index + 1] = item.label;
  });

  const { tCompanyName, tPhone, tEmail, tContinue, tBack } = inTranslations || {};
  const { tBranche, tEmploye, tTagError, tPaySystem, tAccountant, tOptional, tContactMe, tContactPhone, tContactMail, tHowToContact, tHowToContactDesc, tSolution } = inTranslations?.solutionPopup || {};

  return (
    <div
      ref={container}
      className={classnames("popup-root intempus-popup-trigger-root", {
        "is-active": isPopupActive,
      })}>
      {!!button && <span onClick={closePopup} className="intempus-popup-overlay" />}
      <div
        className={classnames("intempus-popup-trigger", {
          "is-last-page": isLastPage,
        })}>
        {!!button && (
          <button onClick={closePopup} className="intempus-popup-close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              <path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" />
            </svg>
          </button>
        )}

        <form onSubmit={onSubmit}>
          <Swiper {...settings}>
            <SwiperSlide>
              <div className="intempus-popup-screen">
                <div className="intempus-popup-header">
                  <p class="title-h3">{content.screen_one.title}</p>
                  <p>{content.screen_one.description}</p>
                </div>

                <select value={values.business} name="business" onChange={onChange} className={classnames({ "is-danger": errors.business })} required>
                  <option disabled selected value="">
                    {tBranche}*
                  </option>
                  {prepareSelectOption(content.screen_one.business_type).map((option) => (
                    <option value={option.key} key={option.key}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <div className="intempus-popup-range">
                  <label>{tEmploye}</label>
                  <input name="employee" value={rangeLabels[values.employee]} hidden className="hidden" />

                  <Slider
                    min={1}
                    max={range_items.length}
                    step={1}
                    value={values.employee}
                    tooltip={false}
                    onChange={(value) => {
                      onChange({
                        target: {
                          name: "employee",
                          value,
                        },
                      });
                    }}
                    labels={rangeLabels}
                  />
                </div>

                <div className="intempus-popup-navs">
                  <div className="wp-block-button is-style-default-outlined">
                    <a className="wp-block-button__link" onClick={submitScreenOne}>
                      {tContinue}
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="intempus-popup-screen">
                <div className="intempus-popup-header">
                  <p class="title-h3">{content.screen_two.title}</p>
                  <p>{content.screen_two.description}</p>
                </div>
                <div className="intempus-popup-tags">
                  {packagesObj["pkgsOriginalOrder"].map(({ key, name }) => {
                    const group = selectedTags?.[key];
                    const isActive = Array.isArray(group) && group.length && group.includes(name);

                    return (
                      <a className={classnames({ "is-active": isActive })} key={name} onClick={toggleTagsSelection.bind(null, name, key)}>
                        {name}
                      </a>
                    );
                  })}
                </div>
                {errors.tags && <p className="intempus-tag-error">{tTagError}</p>}
                <div className="intempus-popup-navs">
                  <div className="wp-block-button is-style-primary-outlined is-style-default-outlined">
                    <a className="wp-block-button__link" onClick={prev}>
                      {tBack}
                    </a>
                  </div>
                  <div className="wp-block-button is-style-default-outlined">
                    <a className="wp-block-button__link" onClick={submitScreenTwo}>
                      {tContinue}
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="intempus-popup-screen">
                <div className="intempus-popup-header">
                  <p class="title-h3">{content.screen_three.title}</p>
                  <p>{content.screen_three.description}</p>
                </div>

                <div className="intempus-popup-body">
                  <div className="intempus-select-group">
                    <select value={values.payment_type} name="payment_type" onChange={onChange}>
                      <option disabled value="">
                        {tPaySystem}
                      </option>

                      {prepareSelectOption(content.screen_three.payment_type).map((option) => (
                        <option value={option.key} key={option.key}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <p class="not-required">{tOptional}</p>
                  </div>

                  <div className="intempus-select-group">
                    <select value={values.account_option} name="account_option" onChange={onChange}>
                      <option disabled value="">
                        {tAccountant}
                      </option>
                      {prepareSelectOption(content.screen_three.accounting_options).map((option) => (
                        <option value={option.key} key={option.key}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <p class="not-required">{tOptional}</p>
                  </div>
                </div>

                <div className="intempus-popup-navs">
                  <div className="wp-block-button is-style-primary-outlined is-style-default-outlined">
                    <a className="wp-block-button__link" onClick={prev}>
                      {tBack}
                    </a>
                  </div>
                  <div className="wp-block-button is-style-default-outlined">
                    <a className="wp-block-button__link" onClick={submitScreenThree}>
                      {tSolution}
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="intempus-popup-screen final-screen">
                <div className="intempus-popup-header">
                  {packagesContent[packageType]?.subtitle && <p>{packagesContent[packageType]?.subtitle}</p>}
                  <p class="title-h3">{packagesContent[packageType]?.title}</p>
                </div>

                {confirmSubmit ? (
                  <div className="intempus-popup-body" style={{ textAlign: "center" }}>
                    {content?.integration?.thank_you || tSuccesSolution}
                  </div>
                ) : (
                  <>
                    <div
                      className="intempus-popup-body"
                      dangerouslySetInnerHTML={{
                        __html: packagesContent[packageType]?.content,
                      }}
                    />
                    <div className="intempus-popup-navs">
                      <div className="wp-block-button is-style-default-outlined">
                        <a className="wp-block-button__link" onClick={submitScreenFour}>
                          {tContactMe}
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="intempus-popup-screen">
                {confirmSubmit ? (
                  <div className="intempus-popup-body" style={{ textAlign: "center" }}>
                    {content?.integration?.thank_you || tSuccesSolution}
                  </div>
                ) : (
                  <>
                    <div className="intempus-popup-header">
                      <p class="title-h3">{tHowToContact}</p>
                      <p>{tHowToContactDesc}</p>
                    </div>

                    <div className="intempus-popup-body">
                      <input className={classnames({ "is-danger": errors.name })} value={values.name} onChange={onChange} name="name" type="text" placeholder={tCompanyName + "*"} />
                      <div className="final-screen-footer">
                        <div className="final-screen-footer-col">
                          <input
                            name="phone"
                            value={values.phone}
                            onChange={onChange}
                            type="phone"
                            placeholder={tPhone}
                            className={classnames({
                              "is-danger": errors.phone,
                            })}
                          />
                          <div className="intempus-popup-navs">
                            <div className="wp-block-button is-style-default-outlined">
                              <button onClick={(e) => onSubmit(e, "phone")} disabled={sending} className="wp-block-button__link">
                                {tContactPhone}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="final-screen-footer-col">
                          <input
                            name="email"
                            value={values.email}
                            onChange={onChange}
                            type="email"
                            placeholder={tEmail}
                            className={classnames({
                              "is-danger": errors.email,
                            })}
                          />
                          <div className="intempus-popup-navs">
                            <div className="wp-block-button is-style-default-outlined">
                              <button onClick={(e) => onSubmit(e, "email")} disabled={sending} className="wp-block-button__link">
                                {tContactMail}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </form>
      </div>
    </div>
  );
}
