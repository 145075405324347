import domReady from "@wordpress/dom-ready";
import { render } from "@wordpress/element";
import "./style.scss";
import App from "./app";

async function handleSubmit(data, url, integration, pipedrive) {
  // Detect device type
  // Detect device type
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent || navigator.vendor || window.opera);
  const deviceType = isMobile ? "Mobile" : "Desktop";

  // Get referrer
  const referrer = document.referrer;

  //const formData = new FormData();
  //formData.append("action", "popup_planner");
  //formData.append("data", JSON.stringify({ ...data, ...integration }));

  function errorNotify() {
    const _payload = { ...data, url: window?.location?.href || "Intempus" };
    const errorFormData = new FormData();
    errorFormData.append("action", "planner_popup_error_notify");
    errorFormData.append("data", JSON.stringify(_payload));

    fetch(url, {
      method: "POST",
      credentials: "same-origin",
      body: errorFormData,
    });
  }

  try {
    if (pipedrive.api_url && pipedrive.api_token) {
      const organization_id = parseInt(pipedrive.organization_id);
      const owner_id = parseInt(pipedrive.owner_id);

      if (isNaN(organization_id)) {
        throw "Organization ID should be number";
        return;
      }

      if (isNaN(owner_id)) {
        throw "Owner ID should be number";
      }

      const payload = {
        title: data.name,
        organization_id,
        owner_id,
      };

      const userPayload = {
        name: data.name,
        org_id: organization_id,
        owner_id,
      };

      if (data?.email) {
        userPayload.email = data.email;
      }

      if (data?.phone) {
        userPayload.phone = data.phone;
      }

      const { account_option_api_key, business_api_key, email_api_key, employees_count_api_key, payment_type_api_key, tags_api_key, selected_plan_api_key, user_device, user_reference, browser_language, site_domain } = pipedrive;

      if (account_option_api_key) {
        payload[account_option_api_key] = data["account_option"];
      }

      if (business_api_key) {
        payload[business_api_key] = data["business"];
      }

      if (email_api_key) {
        payload[email_api_key] = data["email"];
      }

      if (employees_count_api_key) {
        payload[employees_count_api_key] = data["employee"];
      }

      if (selected_plan_api_key) {
        payload[selected_plan_api_key] = data["package"];
      }

      if (selected_plan_api_key) {
        payload[selected_plan_api_key] = data["package"];
      }

      if (payment_type_api_key) {
        payload[payment_type_api_key] = data["payment_type"];
      }

      if (tags_api_key) {
        payload[tags_api_key] = data["tags"];
      }

      if (browser_language) {
        payload[browser_language] = site_domain;
      }

      if (user_device) {
        payload[user_device] = deviceType;
      }

      if (user_reference) {
        payload[user_reference] = referrer;
      }

      const token = `?api_token=${pipedrive.api_token}`;
      const leadsUrl = pipedrive.api_url?.replace(/\/$/, "") + "/leads" + token;
      const userUrl = pipedrive.api_url?.replace(/\/$/, "") + "/persons" + token;

      const response = await fetch(userUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userPayload),
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response?.data?.id) {
            // create lead
            await fetch(leadsUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ...payload,
                person_id: response?.data?.id,
              }),
            });
          } else {
            if (!response?.ok) {
              errorNotify();
            }
            console.log("unable to create user", response);
          }
        });
    } else {
      console.log(`please set pipedrive api url & api token`);
    }

    /*await fetch(url, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    });*/

    return Promise.resolve();
  } catch (error) {
    console.log(error);
    errorNotify();
    return Promise.reject();
  }
}

/**
 * Planner Popup App
 */
function plannerPopup() {
  const root = document.querySelectorAll(".wp-block-intempus-planner-popup, .button-popup-renderer");

  function renderPopup(rootNode, close = true) {
    const button = rootNode.querySelector(".wp-block-button__link");
    const node = rootNode.querySelector(".popup-renderer");
    const hasInit = node.classList.contains("popup-init");
    if (hasInit || !node) return;
    node.classList.add("popup-init");
    const content = JSON.parse(node.getAttribute("data-content"));

    if (!content || !content.screen_one || !content.screen_two || !content.screen_three || !content.integration) {
      console.warn("ACF popup_trigger data not found");
      return;
    }

    render(
      <App
        content={content}
        button={!!close ? button : null}
        submitForm={(data) => {
          return handleSubmit(data, content.ajax_url, content.integration, content.pipedrive);
        }}
      />,
      node
    );
  }

  root.forEach((rootNode) => {
    renderPopup(rootNode);
  });

  const popupRendererRoot = document.querySelectorAll(".popup-renderer-root");
  popupRendererRoot.forEach((rootNode) => {
    renderPopup(rootNode, false);
  });
}

function plannerButtons() {
  document.querySelectorAll(".planner-popup-toggle").forEach(function (button) {
    button.addEventListener("click", function (e) {
      e.preventDefault();

      if (typeof window.__planner_active === "function") {
        window.__planner_active();
      }
    });
  });
}

function triggerOnLink() {
  if (window.location.hash === "#solution") {
    const plannerPopup = document.querySelector(".planner-render-button");
    plannerPopup.click();
  }
}

domReady(() => {
  const isEditor = document.getElementById("editor");
  if (!isEditor) {
    plannerPopup();
    plannerButtons();
    triggerOnLink();
  }
});
